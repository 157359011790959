import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class CookiePolicy extends React.Component {

  render(){

    return(
      <Layout>

        <SEO title="Cookie Policy"/>
        <Link to="/" className="logo">Super!com</Link>
        
        <div className="text-page">
          <div className="center">

            <h2 className="text-page__title">Cookie Policy</h2>

            <p className="text-page__paragraph">
              Cookies refer to electronic information that may be stored on the user's device and enables the website to remember the user’s actions and preferences over a period of time.
            </p>
            <p className="text-page__paragraph">
              Our websites may employ cookies in order to identify the user’s hardware and software (including their browser), customize their preferences, and keep a record of their actions on the website. We use the following categories of cookies:
            </p>
            <ul className="text-page__list">
              <li>Performance cookies, which allow us to count visits and traffic sources so we can measure and improve the performance of our site. These cookies do not store any personally identifiable information.</li>
              <li>Analytical cookies of third parties, which may also include web beacons and pixel tags. We may use third party website analytics tools (i.e., Google Analytics and Facebook pixel) that employ cookies of third parties to collect certain information, e.g. website address links, page views and browser types.</li>
            </ul>
            <p className="text-page__paragraph">
              Providers of the website analytics tools may have access to cookies. Please, follow the links below for details of the privacy policies and opt out choices offered by third parties providing website analytics tools:
            </p>
            <ul className="text-page__list">
              <li>Facebook: <a href="https://www.facebook.com/policy.php" className="text-page__link">https://www.facebook.com/policy.php</a></li>
              <li>Google: <a href="https://policies.google.com/privacy" className="text-page__link">https://policies.google.com/privacy</a></li>
            </ul>
            <p className="text-page__paragraph">
              Cookies lifetime depends on the type of cookies and varies from 1 day up to 2 years. However, you may delete all the cookies at any time.
            </p>
            <p className="text-page__paragraph">
              Enabling cookies is not strictly required to use the primary functions of the website, but it will provide you with a better browsing experience. You may monitor, delete, or disable cookies by changing your browser settings at any time. However, disabling cookies may impair some aspects of the website’s functionality.
            </p>
            <p className="text-page__paragraph">
              SUPER DOT COM LTD <br/>
              Address: 156a Burnt Oak Broadway Edgware Middlesex HA8 0AX, United Kingdom
            </p>
            <p className="text-page__paragraph">
              Date: November 28, 2019
            </p>

          </div>
        </div>

      </Layout>
    );

  }

}

export default CookiePolicy
